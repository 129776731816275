import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Typography, makeStyles, Theme, Box, Checkbox, Grid, CircularProgress, Link, FormHelperText} from '@material-ui/core';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Input from '../common/components/TextInput';
import Button from '../common/components/Button';
import vitalityLogo from '../assets/images/vitality-logo.png';
import {activate, affiliateUserWithVitality} from '../services/api';
import {useAppState} from '../AppContext';
import ModalV2 from '../common/components/ModalV2';
import HelpIcon from '../common/components/HelpIcon';
import Analytics from '../AnalyticsHelper';
import '../material.css';

const useStyles = makeStyles((theme: Theme) => ({
  firstName: {
    marginTop: theme.spacing(4),
  },
  nextButton: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  link: {
    marginBottom: theme.spacing(4),
    fontFamily: 'Averta-Semibold',
  },
  consentInput: {
    paddingLeft: 0,
    paddingTop: 0,
  },
}));

interface FormValues {
  firstname: string;
  lastname: string;
  membership: string;
  consent: boolean;
}

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required().label('First Name'),
  lastname: Yup.string().required().label('Last Name'),
  membership: Yup.string()
    .required()
    .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
    .label('Membership Number'),
  consent: Yup.boolean().required().oneOf([true], 'Consent required'),
});

export default () => {
  const [serverError, setServerError] = useState('');
  const [open, setOpen] = useState(false);
  const {userToken, country, email} = useAppState();
  const intialValues: FormValues = {
    firstname: '',
    lastname: '',
    membership: '',
    consent: false,
  };

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!userToken || !email) {
      history.push('/');
    }
  }, [userToken, history, email]);

  const getConsentLink = () => {
    if (country === 'NZ') {
      return (
        <Link href="#" onClick={() => setOpen(true)} variant="caption" className={classes.link}>
          I consent to AIA Australia (which administers this benefit’s AIA Vitality points allocation) and Groov collecting, using and
          sharing my personal information (including the above and information about the activities I complete) for the purposes of the
          services they provide, including administering this benefit.
        </Link>
      );
    } else {
      return (
        <Link href="#" onClick={() => setOpen(true)} variant="caption" className={classes.link}>
          I consent to AIA Australia and Groov collecting, using and sharing my personal information (including the above and
          information about the activities I complete) for purposes including administering this benefit.
        </Link>
      );
    }
  };

  return (
    <>
      <Box component="div" mb={2} className="center">
        <img src={vitalityLogo} height="40" alt="vitality" />
      </Box>
      <Typography variant="h2" component="div" align="center" gutterBottom>
        AIA Vitality account
      </Typography>
      <Typography component="p" align="center" variant="subtitle1" gutterBottom>
        Enter your AIA Vitality membership details
      </Typography>
      <Formik
        initialValues={intialValues}
        onSubmit={async (values, {setSubmitting}) => {
          const {firstname, lastname, membership} = values;
          Analytics.trackEvent('AccountLinkButtonClicked');
          setSubmitting(true);
          try {
            const affiliateResponse = await affiliateUserWithVitality(email, userToken);

            if (affiliateResponse.status === 202) {
              const response = await activate(firstname, lastname, membership, userToken, country);
              if (response.data) {
                Analytics.trackEvent('AccountsLinkSuccessful');
                Analytics.setUserProperty('OrgId', 'vitality'); //Set user's org ID to vitality
                history.push('/success');
              } else if (response.alreadyExists) {
                Analytics.trackEvent('AccountsAlreadyLinked');
                history.push('/accountslinked');
              } else {
                setServerError('Server Error');
              }
            }
          } catch (e) {
            setServerError(e);
          }
          setSubmitting(false);
        }}
        validationSchema={validationSchema}>
        {props => {
          const {values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container item component="div" alignItems="center" direction="column">
                <Input
                  error={serverError || (errors.firstname && touched.firstname)}
                  fullWidth={true}
                  name="firstname"
                  className={classes.firstName}
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.firstname && touched.firstname && 'First name required'}
                  placeholder="First name"
                />
                <Input
                  error={serverError || (errors.lastname && touched.lastname)}
                  fullWidth={true}
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.lastname && touched.lastname && 'Last name required'}
                  placeholder="Last name"
                />
                <Input
                  error={serverError || (errors.membership && touched.membership)}
                  fullWidth={true}
                  name="membership"
                  value={values.membership}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.membership && touched.membership && 'Membership number must be 10 digits'}
                  placeholder="AIA Vitality membership number"
                />

                {serverError && <FormHelperText error={true}>Invalid account detials</FormHelperText>}

                <Box mt={1}>
                  <Grid container>
                    <Grid item xs={1}>
                      <HelpIcon />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography component="p" align="left" variant="subtitle2" gutterBottom>
                        Fill in your 10 digit AIA Vitality membership number without spaces between the digits.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3}>
                  <Grid container>
                    <Grid item xs={1}>
                      <Checkbox
                        name="consent"
                        value={true}
                        checked={values.consent}
                        inputProps={{'aria-label': 'I confirm'}}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={classes.consentInput}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      {getConsentLink()}
                      {/* <Link
                                                href="#"
                                                onClick={() => setOpen(true)}
                                                variant="caption"
                                                className={classes.link}>
                                                I consent to AIA Australia and Groov collecting, using and sharing my personal information (including the above and information about the activities I complete) for purposes including administering this benefit.
                                            </Link> */}
                    </Grid>
                  </Grid>
                  {touched.consent && errors.consent && <FormHelperText error={true}>{errors.consent}</FormHelperText>}
                </Box>

                <Button type="submit" disabled={isSubmitting} className={classes.nextButton}>
                  {isSubmitting ? <CircularProgress size={25} color="inherit" /> : 'Complete benefit activation'}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <ModalV2 open={open} handleClose={() => setOpen(false)} title="Consent" description="" />
    </>
  );
};
