import React, {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {Grid, Typography, Box} from '@material-ui/core';

import logos from '../assets/images/logos-new.png';
import logos2x from '../assets/images/logos-new.png';
import logos3x from '../assets/images/logos-new.png';
import appstore from '../assets/images/appstore.png';
import appstore2x from '../assets/images/appstore@2x.png';
import appstore3x from '../assets/images/appstore@3x.png';
import playstore from '../assets/images/playstore.png';
import playstore2x from '../assets/images/playstore@2x.png';
import playstore3x from '../assets/images/playstore@3x.png';
//import appStoreLogo from '../assets/images/app-store.png';
//import googlePlayLogo from '../assets/images/google-play.png';
import Button from '../common/components/Button';
import {useAppDispatch} from '../AppContext';
import {storeUrls} from '../common/constants';
import Analytics from '../AnalyticsHelper';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useAppDispatch();

  const cc = query.get('cc') || 'AU';
  const handleNext = () => history.push('/account');
  useEffect(() => {
    dispatch({
      type: 'setCountry',
      payload: cc.toUpperCase(),
    });
  });

  useEffect(() => {
    dispatch({type: 'resetUserToken'});
    dispatch({type: 'resetEmail'});
  }, []);

  const handleAppleStoreClick = () => {
    Analytics.trackEvent('AppStoreIconClicked');
  };
  const handleGooglePlayClick = () => {
    Analytics.trackEvent('GooglePlayIconClicked');
  };

  return (
    <>
      <Box component="div" mb={2}>
        <img width="98%" src={logos} srcSet={`${logos2x} 2x, ${logos3x} 3x`} alt="Groov and vitality" />
      </Box>
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        1. Create Groov account
      </Typography>
      <Typography component="span" align="center" variant="subtitle1" gutterBottom>
        Download the Groov app and create an account.
      </Typography>
      <Box component="div" my={4}>
        <Grid container item component="div" justify="center" spacing={3}>
          <Grid item xs={6}>
            <a href={storeUrls.apple} onClick={handleAppleStoreClick} target="_blank" rel="noopener noreferrer">
              <img width="98%" src={appstore} srcSet={`${appstore2x} 2x, ${appstore3x} 3x`} alt="Groov and vitality" />
            </a>
          </Grid>
          <Grid item xs={6}>
            <a href={storeUrls.google} onClick={handleGooglePlayClick} target="_blank" rel="noopener noreferrer">
              <img width="98%" src={playstore} srcSet={`${playstore2x} 2x, ${playstore3x} 3x`} alt="Groov and vitality" />
            </a>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        2. Link your accounts
      </Typography>
      <Typography component="span" align="center" variant="subtitle1" gutterBottom>
        Once you have created your Groov account, click below to link it to your AIA Vitality membership.
      </Typography>
      <Box component="div" mt={4} mb={4}>
        <Button onClick={handleNext} color="primary">
          Link to AIA Vitality
        </Button>
      </Box>
    </>
  );
};
