import * as React from 'react';

type Action =
  | {type: 'setUserToken'; payload: string}
  | {type: 'setCountry'; payload: string}
  | {type: 'setEmail'; payload: string}
  | {type: 'resetUserToken'}
  | {type: 'resetCountry'}
  | {type: 'resetEmail'};
type Dispatch = (action: Action) => void;
type State = {
  userToken: string;
  country: string;
  email: string;
};
type CountProviderProps = {children: React.ReactNode};

const defaultState: State = {userToken: '', country: 'AU', email: ''};

const AppStateContext = React.createContext<State | undefined>(undefined);
const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function AppReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setUserToken': {
      window.localStorage.setItem('userToken', action.payload);
      return {...state, userToken: action.payload};
    }
    case 'setCountry': {
      window.localStorage.setItem('countryCode', action.payload);
      return {...state, country: action.payload};
    }
    case 'setEmail': {
      console.log(action.payload);
      window.localStorage.setItem('email', action.payload);
      return {...state, email: action.payload};
    }
    case 'resetUserToken': {
      window.localStorage.removeItem('userToken');
      return {...state, userToken: defaultState.userToken};
    }
    case 'resetCountry': {
      window.localStorage.removeItem('countryCode');
      return {...state, country: defaultState.country};
    }
    case 'resetEmail': {
      window.localStorage.removeItem('email');
      return {...state, email: defaultState.email};
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function AppProvider({children}: CountProviderProps) {
  const [state, dispatch] = React.useReducer(AppReducer, {
    userToken: window.localStorage.getItem('userToken') || defaultState.userToken,
    country: window.localStorage.getItem('countryCode') || defaultState.country,
    email: window.localStorage.getItem('email') || defaultState.email,
  });
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppDispatchProvider');
  }
  return context;
}

export {AppProvider, useAppState, useAppDispatch};
