import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import questionIcon from '../../assets/images/ic-help.svg';
import {makeStyles, Theme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        borderRadius: theme.spacing(4),
        marginRight: theme.spacing(2),
        width: 238,
    },
}));

export default function HelpIcon() {
    const classes = useStyles();
    return (
        <PopupState variant="popover" popupId="help-popup-popover">
            {popupState => (
                <div>
                    <img src={questionIcon} height="18" alt="help" {...bindTrigger(popupState)} />
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        classes={{
                            paper: classes.paper,
                        }}>
                        <Box p={2}>
                            <Typography variant="caption">
                                Your AIA Vitality membership number can be found in the Account section of your AIA
                                Vitality app.
                            </Typography>
                        </Box>
                    </Popover>
                </div>
            )}
        </PopupState>
    );
}
