import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Grid, Container, Box} from '@material-ui/core';
import Header from './common/components/Header';
interface Props {
  children: React.ReactNode;
}
export default ({children}: Props) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container component="header" maxWidth="md">
      <Header />
    </Container>
    <Container style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
      <Grid container item xs={12} sm={6} md={4} lg={3} direction="column" alignItems="center">
        <Box component="div" mt={7}>
          <Grid container item justify="center" alignItems="center" direction="column">
            {children}
          </Grid>
        </Box>
      </Grid>
    </Container>
  </ThemeProvider>
);
