import axios, {AxiosError, AxiosResponse} from 'axios';
import Analytics from '../AnalyticsHelper';
import {LoginResponse, ApiResponse} from './types';

interface ActivateResponse {}

const endPoints = {
  // TODO: Move to environment variables

  // resetPassword: `${process.env.URL_IDENTITY_SERVICE}/api/registration/password/reset`,
  // token: `${process.env.URL_IDENTITY_SERVICE}/connect/token`,
  // externalIdentity: `${process.env.URL_EXTERNAL_SERVICE}/external-identity`,
  // resetPassword: `https://login.preprod.mentemia.com/api/registration/password/reset`,
  // token: `https://login.preprod.mentemia.com/connect/token`,
  // externalIdentity: `https://api.preprod.mentemia.com/external-identity`,

  // Test environment
  // resetPassword: `https://login.test.mentemia.com/api/registration/password/reset`,
  // token: `https://login.test.mentemia.com/connect/token`,
  // externalIdentity: `https://apigateway.test.mentemia.com/external-identity`,

  // Prod environment
  resetPassword: `${process.env.REACT_APP_AUTHORITY || 'https://login.mentemia.com'}/api/registration/password/reset`,
  token: `${process.env.REACT_APP_AUTHORITY || 'https://login.mentemia.com'}/connect/token`,
  externalIdentity: `${process.env.REACT_APP_EXTERNAL_IDENTITY || 'https://api.mentemia.com/external-identity'}`,
  subscriptionService: `${process.env.REACT_APP_GATEWAY_URL || 'https://api.mentemia.com'}/subscription/user/externalAffiliate`,
};

const clientConfig = {
  clientId: process.env.CLIENT_ID || 'mentemia.external',
  clientSecret: process.env.CLIENT_SECRET || '34234dfsggahFfSFDYWUSGHSDRGYHSDHSDHS23534252VSHF2bJHJ3411546FFSFGhhsRRz',
};

const ENTITY_CODE = 'vitality';

const adaptToLoginResponse = (result: AxiosResponse<any>): LoginResponse => {
  return {
    accessToken: result.data.access_token,
    expiry: result.data.expires_in,
    refreshToken: result.data.refresh_token,
    status: result.status,
    statusText: result.statusText,
  };
};

export const authenticate = async (username: string, password: string): Promise<LoginResponse> => {
  try {
    var formData = new FormData();
    formData.append('client_id', clientConfig.clientId);
    formData.append('client_secret', clientConfig.clientSecret);
    formData.append('grant_type', 'password');
    formData.append('scope', 'openid profile registration integration');
    formData.append('username', username);
    formData.append('password', password);
    const result = await axios.post(endPoints.token, formData);
    const response = adaptToLoginResponse(result);
    return response;
  } catch (error) {
    return handleErrorResposne('Login', error);
  }
};

export const getAppToken = async (): Promise<LoginResponse> => {
  try {
    var formData = new FormData();
    formData.append('client_id', clientConfig.clientId);
    formData.append('client_secret', clientConfig.clientSecret);
    formData.append('grant_type', 'client_credentials');
    formData.append('scope', 'registration');
    const result = await axios.post(endPoints.token, formData);
    const response = adaptToLoginResponse(result);
    return response;
  } catch (error) {
    return handleErrorResposne('AppLogin', error);
  }
};

export const activate = async (
  firstName: string,
  lastName: string,
  membership: string,
  token: string,
  country: string,
): Promise<any> => {
  const body = {
    externalId: membership,
    externalEntityCode: 'VITALITY',
    metadata: {
      firstName,
      lastName,
      consentSigned: 1,
    },
    status: 'ACTIVE',
    country,
  };

  try {
    const config = {
      headers: {Authorization: `Bearer ${token}`},
    };
    const result = await axios.post(endPoints.externalIdentity, body, config);
    return {
      data: result.data,
      alreadyExists: false,
    };
  } catch (error) {
    if (error.response.data?.errorCode === 'duplicate_user_entry') {
      return {
        alreadyExists: true,
      };
    } else {
      return {};
    }
  }
};

export const resetPassword = async (email: string): Promise<ApiResponse> => {
  const {accessToken} = await getAppToken();
  try {
    const config = {
      headers: {Authorization: `Bearer ${accessToken}`},
    };
    const result = axios.post(endPoints.resetPassword, {email}, config);
    return result;
  } catch (error) {
    return handleErrorResposne('ResetPassword', error);
  }
};

export const getEntity = async (token?: string): Promise<ApiResponse> => {
  try {
    const config = {
      headers: {Authorization: `Bearer ${token}`},
      params: {
        entity_code: ENTITY_CODE,
      },
    };
    const result = await axios.get(endPoints.externalIdentity, config);
    return result;
  } catch (error) {
    return handleErrorResposne('GetVitalityAccount', error);
  }
};

export const affiliateUserWithVitality = async (email: string, token?: string): Promise<ApiResponse> => {
  try {
    const config = {
      headers: {Authorization: `Bearer ${token}`},
    };

    console.log(email);
    const result = await axios.post(endPoints.subscriptionService, {orgId: ENTITY_CODE, email: email}, config);
    return result;
  } catch (error) {
    return handleErrorResposne('AffiliateUserWithVitality', error);
  }
};

const tryParseNumber = (str: any, defaultValue: number = 0): number => {
  console.log('Value to be parsed: ' + str);
  let retValue = defaultValue;
  if (str && !isNaN(str)) {
    console.log('Parsing: ' + str);
    retValue = parseInt(str, 10);
  }
  return retValue;
};

const handleErrorResposne = (context: string, error: AxiosError): ApiResponse => {
  let result: ApiResponse = {status: 0, statusText: ''};
  if (error.response) {
    result.status = error.response.status;
    result.statusText = error.response.statusText;
    if (error.response.data) {
      result.details = error.response.data.details;
      result.errorCode = tryParseNumber(error.response.data.error);
      result.errorMessage = error.response.data.error_description;
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
  console.log(error.message);
  Analytics.trackEvent('APICallFailed', context, result.errorMessage ? result.errorMessage : result.statusText);
  return result;
};
