import React, {useState, MouseEvent, useEffect} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import {
  Typography,
  makeStyles,
  Theme,
  Box,
  InputAdornment,
  Button as CoreButton,
  CircularProgress,
  Grid,
  FormHelperText,
  Link,
} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';
import LockImage from '../assets/images/ic-lock.svg';
import UserImage from '../assets/images/ic-user-alt.svg';
import Input from '../common/components/TextInput';
import Button from '../common/components/Button';
import {authenticate, getEntity} from '../services/api';
import groovLogo from '../assets/images/groov-full-logo@2x.png';
import groovLogo2x from '../assets/images/groov-full-logo@2x.png';
import groovLogo3x from '../assets/images/groov-full-logo@2x.png';
import {useAppDispatch} from '../AppContext';
import Analytics from '../AnalyticsHelper';

const ShowButton = styled(CoreButton)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(4),
  color: theme.palette.secondary.main,
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&:hover': {
    background: theme.palette.background.default,
  },
}));

const HideButton = styled(CoreButton)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(4),
  color: 'white',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  nextButton: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    minWidth: theme.spacing(21),
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  userNameInput: {
    marginTop: theme.spacing(2),
  },
  lockIcon: {
    width: 14,
    height: 20,
  },
  userIcon: {
    width: 20,
    height: 20,
  },
  serverError: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
}));

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

export default () => {
  const [serverError, setServerError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const intialValues: FormValues = {
    email: '',
    password: '',
  };

  const history = useHistory();
  const classes = useStyles();

  const onSubmit = async (email: string, password: string) => {
    try {
      const {accessToken} = await authenticate(email, password);
      if (accessToken) {
        Analytics.trackEvent('LoginCompleted');
        dispatch({
          type: 'setUserToken',
          payload: accessToken,
        });
        dispatch({
          type: 'setEmail',
          payload: email,
        });
        const {data} = await getEntity(accessToken);
        if (data.length > 0) {
          Analytics.trackEvent('AccountsAlreadyLinked');
          history.push('/accountslinked');
        } else {
          history.push('/vitalityaccount');
        }
      } else {
        setServerError('Invalid account credentials');
      }
    } catch (e) {
      setServerError(e);
    }
  };
  const navigateToForgotPassword = (e: MouseEvent) => {
    e.preventDefault();
    history.push('/forgotpassword');
  };

  useEffect(() => {
    console.log('reset user info');
    dispatch({type: 'resetUserToken'});
    dispatch({type: 'resetEmail'});
  }, []);

  return (
    <>
      <Box component="div" mb={2}>
        <img src={groovLogo} srcSet={`${groovLogo2x} 2x, ${groovLogo3x} 3x`} alt="Groov" />
      </Box>
      <Typography variant="h2" component="div" align="center" gutterBottom>
        Groov account
      </Typography>
      <Typography component="p" align="center" variant="subtitle1" gutterBottom>
        Enter your Groov app sign in details.
      </Typography>
      <Formik
        initialValues={intialValues}
        onSubmit={async (values, {setSubmitting}) => {
          Analytics.trackEvent('LoginClicked');
          const {email, password} = values;
          setSubmitting(true);
          await onSubmit(email, password);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}>
        {props => {
          const {values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container item component="div" alignItems="center" direction="column">
                <Input
                  error={serverError || (errors.email && touched.email)}
                  fullWidth={true}
                  name="email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className={classes.userIcon} src={UserImage} alt="" />
                      </InputAdornment>
                    ),
                  }}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email && touched.email && 'Email Required'}
                  placeholder="Email"
                />
                <Input
                  error={serverError || (errors.password && touched.password)}
                  fullWidth={true}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className={classes.lockIcon} src={LockImage} alt="" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <HideButton onClick={() => setShowPassword(!showPassword)}>Hide</HideButton>
                        ) : (
                          <ShowButton onClick={() => setShowPassword(!showPassword)}>Show</ShowButton>
                        )}
                      </InputAdornment>
                    ),
                    type: showPassword ? 'text' : 'password',
                  }}
                  helperText={errors.password && touched.password && 'Password Required'}
                  placeholder="Password"
                />
                {serverError && (
                  <FormHelperText error={true} className={classes.serverError}>
                    Invalid account credentials.
                  </FormHelperText>
                )}
                <Button type="submit" disabled={isSubmitting} className={classes.nextButton}>
                  {isSubmitting ? <CircularProgress size={25} color="inherit" /> : 'Next'}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <Link href="#" onClick={navigateToForgotPassword} variant="subtitle1">
        Forgot your password?
      </Link>
      <Box mb={4} />
    </>
  );
};
