import React, { useState } from 'react';
import logo from '../assets/images/password.png';
import logo2x from '../assets/images/password@2x.png';
import logo3x from '../assets/images/password@3x.png';

import {Typography, Box, makeStyles, Theme, Grid, InputAdornment, CircularProgress, FormHelperText} from '@material-ui/core';
//import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Input from '../common/components/TextInput';
import Button from '../common/components/Button';
import UserImage from '../assets/images/ic-user-alt.svg';
//import { useAppState } from '../AppContext';
import { resetPassword } from '../services/api';
import { useHistory } from 'react-router-dom';
import Analytics from '../AnalyticsHelper';

const useStyles = makeStyles((theme: Theme) => ({
    email: {
        margin: theme.spacing(7, 'auto'),
    },
    submit: {
        marginBottom: theme.spacing(4),
    },
    userIcon: {
        width: 20,
        height: 20,
    },
    serverError: {
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
}));

export default () => {
    const classes = useStyles();
    const history = useHistory();
    //const { appToken } = useAppState();
    const [serverError, setServerError] = useState(false);

    const intialValues = {email: ''};
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required('Required'),
    });

    const sendEmail = async (email: string) => {
        const {status} =  await resetPassword(email);
        if(status !== 200) {
            setServerError(true);
        }
    };
    return (
        <>
            <Box component="div" mb={2}>
                <img height="80" width="80" src={logo} srcSet={`${logo2x} 2x, ${logo3x} 3x`} alt="forgot password" />
            </Box>
            <Typography variant="h2" component="h1" align="center" gutterBottom>
                Forgot your password?
            </Typography>
            <Typography component="p" align="center" variant="subtitle1" gutterBottom>
                Enter your email address and we’ll send instructions to reset your password.
            </Typography>

            <Formik
                initialValues={intialValues}
                onSubmit={async (values, {setSubmitting}) => {
                    const { email } = values;
                    setSubmitting(true);
                    Analytics.trackEvent('ForgotPasswordClicked');
                    await sendEmail(email);
                    Analytics.trackEvent('ResetPasswordMailSent');
                    history.push('/checkemail');
                    setSubmitting(false);
                }}
                validationSchema={validationSchema}>
                {props => {
                    const {touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit} = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <Box component="div" mb={2}>
                                <Grid container item component="div" alignItems="center" direction="column">
                                    <Input
                                        error={errors.email && touched.email}
                                        fullWidth={true}
                                        name="email"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img className={classes.userIcon} src={UserImage} alt="" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        className={classes.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={errors.email && touched.email && 'Email Required'}
                                        placeholder="Email"
                                    />
                                    {serverError && (
                                        <FormHelperText error={true} className={classes.serverError}>
                                            Error in sending reset email link
                                        </FormHelperText>
                                    )}
                                    <Button type="submit" disabled={isSubmitting} className={classes.submit}>
                                        {isSubmitting ? <CircularProgress size={25} color="secondary" /> : 'Reset your password'}
                                    </Button>
                                </Grid> 
                            </Box>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};
