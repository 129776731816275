import React from 'react';
import {TextField} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import colors from '../../colors';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    inputRoot: {
        borderRadius: theme.spacing(4),
        borderColor: colors.mysticGrey,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        backgroundColor: 'white',
    },
    inner: {
        borderRadius: '0 !important',
    },
}));

export default ({InputProps = {}, ...other}) => {
    const classes = useStyles();
    const props = {
        ...InputProps,
        classes: {
            root: classes.inputRoot,
            input: classes.inner,
        },
    };

    return <TextField className={classes.root} variant="outlined" InputProps={props} {...other} />;
};
