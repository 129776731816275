import Countly from 'countly-sdk-web';

class AnalyticsHelper {
    private countly: any;
    constructor() {
        this.initCountly();
    }

    private initCountly = () => {
        this.countly = Countly || {};
        this.countly.q = Countly.q || [];

        // this.countly.app_key = '2373155d9c0abf75f4a095d6d88f8a023c19f099';//process.env.REACT_APP_ANALYTICS_API_KEY;
        // this.countly.url = 'https://analytics.test.mentemia.com/';//process.env.REACT_APP_ANALYTICS_API_URL;
        
        // Prod env
        this.countly.app_key = process.env.REACT_APP_ANALYTICS_API_KEY || 'e93a06e003fe22938734843df0050666537eb3c2';
        this.countly.url = process.env.REACT_APP_ANALYTICS_API_URL || 'https://analytics.mentemia.com/';
        
        
        this.countly.q.push(['track_pageview']);
        this.countly.q.push(['track_sessions']);
        this.countly.q.push(['track_clicks']);
        this.countly.q.push(['track_scrolls']);
        this.countly.init();
    };

    trackPageView = (pageName: string) => {
        this.countly.q.push(['track_pageview', pageName]);
    };

    setUserProperty = (key: string, value: string) => {
        this.countly.q.push(['userData.set', key, value]);
    };

    trackEvent = (eventName: string, segmentName?: string, eventValue?: string) => {
        let segName = segmentName ? segmentName : '';
        let segValue = eventValue ? eventValue : '';
        this.countly.q.push([
            'add_event',
            {
                key: eventName,
                segmentation: {
                    [segName]: segValue,
                },
            },
        ]);
    };

    trackEventV2 = (eventName: string, extraInfo: {[index: string]: string}) => {
        this.countly.q.push([
            'add_event',
            {
                key: eventName,
                segmentation: {...extraInfo},
            },
        ]);
    };

    init = () => {};
}

const Analytics = new AnalyticsHelper();
export default Analytics;
