import {styled} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledButton = styled(Button)(({theme}) => ({
    color: 'white',
    padding: theme.spacing(2, 6),
    borderRadius: theme.spacing(4),
    background: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:hover': {
        background: theme.palette.primary.main,
    },
}));

export default StyledButton;
