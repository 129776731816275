import React from 'react';
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useAppState } from '../../AppContext';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

interface Props {
    open: boolean;
    handleClose: () => void;
    title?: string;
    description: string;
}

export default function CustomizedDialogs(props: Props) {
    const {country} = useAppState();

    // const getAIAPolicyUrl = () => {
    //     let policyUrl = "https://www.aia.com.au/en/individual/index/privacy-policy.html";
    //     if(country === 'NZ') {
    //         policyUrl = "https://www.aia.co.nz/en/index/privacy-statement.html";
    //     } 
    //     return (
    //         <a target='_blank' rel="noopener noreferrer"  href={policyUrl}>here</a>
    //     )
    // };

    const getAIAPolicyText = () => {
        if(country === 'NZ') {
            return (
                <Typography gutterBottom>
                    By activating this benefit, you confirm that you have read and consent to the collection, use, 
                    handling and disclosure of personal information in the manner described in the Privacy Policy 
                    for AIA Australia Ltd, located in Australia, (available <a target='_blank' rel="noopener noreferrer" href="https://www.aia.co.nz/en/index/privacy-statement.html">here</a>) and Mentemia Limited, 
                    located in New Zealand, (available <a target='_blank' rel="noopener noreferrer"  href='https://www.mentemia.com/mentemia-privacy-policy'>here</a>). You acknowledge that these policies may be 
                    updated from time to time, and that any personal information AIA Australia and/or Mentemia hold will 
                    be governed by that entity’s most current Privacy Policy. In addition to the purposes set out in 
                    these privacy policies, personal information may be used to manage our relationship and communicate with you, 
                    and you may be contacted by AIA Australia, AIA New Zealand and Mentemia for the purposes of offering, 
                    verifying and administering this benefit.
                </Typography>
            )
        } else {
            return (
                <Typography gutterBottom>
                    By activating this benefit, you confirm that you have read and consent to the collection, use, 
                    handling and disclosure of personal information in the manner described in the Privacy Policy 
                    each of AIA Australia Ltd (available <a target='_blank' rel="noopener noreferrer"  href="https://www.aia.com.au/en/individual/index/privacy-policy.html">here</a>) and Mentemia Limited, located in New Zealand, 
                    (available <a target='_blank' rel="noopener noreferrer"  href='https://www.mentemia.com/mentemia-privacy-policy'>here</a>) and agree that these documents may be updated from time to time, and 
                    that any personal information AIA Australia and/or Mentemia hold will be governed by that entity’s 
                    most current Privacy Policy. In addition to the purposes set out in these privacy policies, personal 
                    information may be used and you may be contacted by AIA Australia and Mentemia for the purposes of offering, 
                    verifying and administering this benefit.
                </Typography>
            )
        }  
    };

    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                    &nbsp;
                </DialogTitle>
                <DialogContent dividers>
                    {getAIAPolicyText()}
                </DialogContent>
            </Dialog>
        </div>
    );
}
