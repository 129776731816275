import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createBrowserHistory} from 'history';
import * as serviceWorker from './serviceWorker';
import Analytics from './AnalyticsHelper';

const history = createBrowserHistory();
history.listen(location => {
  Analytics.trackPageView(location.pathname + location.hash);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
