import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import CheckEmail from './pages/CheckEmail';
import VitalityAccount from './pages/VitalityAccount';
import AlreadyLinked from './pages/AlreadyLinked';
import Success from './pages/Success';
import Layout from './Layout';
import {AppProvider} from './AppContext';

function App() {
  return (
    <AppProvider>
      <Layout>
        <Router>
          <Switch>
            <Route path="/account">
              <Login />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPassword />
            </Route>
            <Route path="/checkemail">
              <CheckEmail />
            </Route>
            <Route path="/vitalityaccount">
              <VitalityAccount />
            </Route>
            <Route path="/accountslinked">
              <AlreadyLinked />
            </Route>
            <Route path="/success">
              <Success />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Layout>
    </AppProvider>
  );
}

export default App;
