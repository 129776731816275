import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import './index.css';
import './fonts.css';
import 'react-smartbanner/dist/main.css';
import colors from './colors';

let theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.blue,
        },
        secondary: {
            main: colors.royalBlue,
        },
        text: {
            primary: colors.slateGrey,
        },
        background: {
            default: colors.whisperWhite,
        },
        grey: {
            400: colors.mysticGrey,
        },
    },
    typography: {
        fontFamily: ['Averta', 'Averta-Bold', 'Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: '2.65rem',
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        h2: {
            fontSize: '1.5rem',
            color: colors.darkGrey,
            fontFamily: ['Averta-ExtraBold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
        },
        subtitle1: {
            fontSize: '1rem',
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            color: colors.slateGrey,
            lineHeight: 1.5,
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontFamily: ['Averta-Semibold', 'Helvetica', 'Verdana', 'sans-serif'].join(','),
            color: colors.slateGrey,
        },
    },
});
theme = responsiveFontSizes(theme, {breakpoints: ['xs', 'sm']});

export default theme;
