import React, {useEffect} from 'react';
import spin from '../assets/images/spin.png';
import spin2x from '../assets/images/spin@2x.png';
import spin3x from '../assets/images/spin@3x.png';
import logos from '../assets/images/logos-new.png';
import logos2x from '../assets/images/logos-new.png';
import logos3x from '../assets/images/logos-new.png';
import {Typography, Box, makeStyles, Theme} from '@material-ui/core';
import {useAppDispatch, useAppState} from '../AppContext';
import SmartBanner from 'react-smartbanner';
import {UAParser} from 'ua-parser-js';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 96,
    marginBottom: 20,
  },
}));

interface Props {
  title?: string;
}

export default (props: Props) => {
  const history = useHistory();
  const {country = '', userToken, email} = useAppState();
  const dispatch = useAppDispatch();
  const points = country === 'NZ' ? 75 : 100;

  const classes = useStyles();
  const ua = new UAParser(window.navigator.userAgent);
  const osType = ua.getOS().name === 'iOS' ? 'ios' : 'android';

  const smartBannerSettings = {
    price: {ios: 'GET', android: 'GET'},
  };

  const resetUserInfo = () => {
    dispatch({type: 'resetUserToken'});
    dispatch({type: 'resetEmail'});
  };

  useEffect(() => {
    if (!userToken || !email) {
      history.push('/');
    }
    resetUserInfo();
  }, []);

  return (
    <>
      <Box component="div" mb={2}>
        <img src={logos} srcSet={`${logos2x} 2x, ${logos3x} 3x`} alt="Groov and vitality" />
      </Box>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        {props.title || 'Accounts linked successfully'}
      </Typography>
      <Typography component="p" align="center" variant="subtitle1" gutterBottom>
        Start using the Groov app to earn AIA Vitality points.
      </Typography>
      <Box component="div" my={5}>
        <img src={spin} srcSet={`${spin2x} 2x, ${spin3x} 3x`} alt="Linked Accounts" />
      </Box>
      <Typography variant="h2" component="h2" align="center" gutterBottom>
        How to earn AIA Vitality Points
      </Typography>
      <Typography component="p" align="center" variant="subtitle1" gutterBottom>
        Complete one activity each day, four days each week and earn {points} AIA Vitality Points.
      </Typography>
      {/* <Box component="div" mt={4} mb={5}>
                <Button onClick={() => {}} color="primary">
                    Open App
                </Button>
            </Box> */}
      <div className={classes.buttonContainer}>
        <SmartBanner title={'Groov'} position={'bottom'} force={osType} price={smartBannerSettings.price} daysHidden={0} />
      </div>
    </>
  );
};
