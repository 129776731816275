import React from 'react';
import logo from '../../assets/images/groov-round-logo.svg';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: theme.spacing(3),
    },
  }),
);

const Header: React.FC = props => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <img className="app-logo" src={logo} alt="Logo" />
    </div>
  );
};

export default Header;
