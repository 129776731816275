import React from 'react';
import logo from '../assets/images/email.png';
import logo2x from '../assets/images/email@2x.png';
import logo3x from '../assets/images/email@3x.png';

import {Typography, Box, Link} from '@material-ui/core';

export default () => {
    return (
        <>
            <Box component="div" mb={2}>
                <img src={logo} srcSet={`${logo2x} 2x, ${logo3x} 3x`} alt="Check email" />
            </Box>
            <Typography variant="h2" component="h1" align="center" gutterBottom>
                Check your email
            </Typography>
            <Typography component="p" align="center" variant="subtitle1" gutterBottom>
                Check your email and follow the link we sent to reset your password. Can’t find it? Please check in your
                spam folder as they sometimes end up there.
            </Typography>

            <Box component="div" mt={4}>
                <Typography component="p" align="center" variant="subtitle2" gutterBottom>
                    Can’t Sign in? Contact our&nbsp;
                    <Link href="https://www.mentemia.com/mentemia-customer-service" variant="subtitle2">
                        Customer Service.
                    </Link>
                </Typography>
            </Box>

            
        </>
    );
};
